/* eslint-disable @next/next/no-img-element */
import { ImageType } from 'core/lib/graphql/types.d';
import { FC, useMemo, ImgHTMLAttributes } from 'react';
import LazyLoad, { LazyLoadProps } from 'react-lazyload';

export interface SmartImageType extends Omit<ImageType, 'name' | 'path'> {
  webp?: string;
  avif?: string;
}

export interface SmartImageTypeSize extends SmartImageType {
  size: string;
  media?: string;
}

export interface SmartImageProps {
  image: SmartImageType;
  sizes?: SmartImageTypeSize[];
  className?: string;
  lazy?: boolean;
  lazyProps?: LazyLoadProps;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  skipResolutionSizes?: boolean;
  resolutionSizes?: Partial<SmartImageType>;
}

const explodeUrl = (url: string) => {
  const [ext, ...rest] = (url ?? '').split('.').reverse();
  const filename = rest.reverse().join('.');
  return { ext, filename };
};

const generateResolutionSet = (url: string) => {
  const { ext, filename } = explodeUrl(url);
  return `${filename}@3x.${ext} 3x, ${filename}@2x.${ext} 2x, ${url} 1x`;
};

const generateSizesResolutionSet = (sizes: SmartImageTypeSize[]) =>
  ['url', 'avif', 'webp']
    .map(key => {
      return {
        srcSet: sizes
          .filter(it => it.hasOwnProperty(key))
          .map(it => {
            const { ext, filename } = explodeUrl(it.url);
            return `${filename}@3x.${ext} 3x ${it.size}, ${filename}@2x.${ext} 2x ${it.size}, ${it.url} 1x, ${it.size}`;
          })
          .join(', '),
        type: key === 'url' ? undefined : `image/${key}`,
      };
    })
    .filter(it => it.srcSet.length > 0);

const extToMimeDict: Record<string, string> = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg',
};

export const SmartImage: FC<SmartImageProps> = ({
  image,
  imageProps,
  sizes,
  className,
  lazy,
  lazyProps,
  skipResolutionSizes,
  resolutionSizes,
}) => {
  const sizesSources = useMemo(
    () => (sizes != null ? generateSizesResolutionSet(sizes) : []),
    [sizes],
  );
  const { ext } = useMemo(() => explodeUrl(image.url), [image]);
  const imageEl = useMemo(
    () => (
      <picture className={className}>
        {image.avif != null && (
          <source
            srcSet={
              resolutionSizes?.avif != null
                ? resolutionSizes.avif
                : skipResolutionSizes
                ? image.avif
                : generateResolutionSet(image.avif)
            }
            type="image/avif"
          />
        )}
        {image.webp != null && (
          <source
            srcSet={
              resolutionSizes?.webp != null
                ? resolutionSizes.webp
                : skipResolutionSizes
                ? image.webp
                : generateResolutionSet(image.webp)
            }
            type="image/webp"
          />
        )}
        <source
          srcSet={resolutionSizes?.url != null ? resolutionSizes.url : image.url}
          type={extToMimeDict[ext] ?? 'image/jpeg'}
        />
        {sizesSources.map(it => (
          <source key={it.srcSet} srcSet={it.srcSet} type={it.type} />
        ))}
        <img
          {...imageProps}
          src={image.url}
          alt={image.alt ?? ''}
          srcSet={
            resolutionSizes?.url != null
              ? resolutionSizes.url
              : skipResolutionSizes
              ? undefined
              : generateResolutionSet(image.url)
          }
        />
      </picture>
    ),
    /* eslint-disable react-hooks/exhaustive-deps */
    [image, className, sizesSources, imageProps, skipResolutionSizes, ext],
  );
  return lazy ? <LazyLoad {...lazyProps}>{imageEl}</LazyLoad> : imageEl;
};
